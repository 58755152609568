<template>
  <div>
    <v-btn
      v-on="$listeners"
      :color="color"
      :text="text || light"
      class="v-btn--active"
      v-bind="$attrs"
      v-if="deviceOrientation || lngLat"
    >
      <span :class="textColorClass">
        <span v-if="lngLat">
          GPS
          {{ lngLat }}
        </span>
        <span v-if="orientation">
          <v-icon v-if="deviceOrientation" size="22" class="ml-1">mdi-navigation</v-icon>
          {{ deviceOrientation }}
        </span>
      </span>
    </v-btn>
  </div>
</template>

<script>
import config from '../config';
import Orientation from '../services/orientation';
import { lngLatFormatter } from '../utils/map';
export default {
  props: {
    confirmed: {
      type: Boolean,
      default: true
    },
    light: {
      type: Boolean,
      default: false
    },
    coordinates: {
      type: Object,
      default: null
    },
    orientation: {
      type: Object,
      default: null
    },
    text: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      obj: null,
      orientationEnabled: config.orientation
    };
  },
  created() {
    this.obj = new Orientation();
  },
  computed: {
    color() {
      let res = ``;
      if (this.confirmed && this.light) {
        return '#9dc4eb';
      }

      if (this.confirmed) {
        res += 'primary';
        if (this.text) {
          res += ' lighten-3';
        }
      } else {
        res += 'orange';
        if (this.text) {
          res += ' lighten-2';
        }
      }
      return res;
    },
    textColorClass() {
      if (this.confirmed && this.light) {
        return 'geo-confirmed--text';
      }
      if (!this.text) {
        return 'white--text';
      }
      if (this.confirmed) {
        return 'primary--text';
      } else {
        return 'orange--text';
      }
    },
    lngLat() {
      const lngLat = { lng: null, lat: null };
      lngLat.lng = this.coordinates?.lng || this.coordinates?.longitude;
      lngLat.lat = this.coordinates?.lat || this.coordinates?.latitude;
      if (!lngLat.lng || !lngLat.lat) {
        return;
      }
      return lngLatFormatter({ lngLat });
    },
    deviceOrientation() {
      this.obj.set(this.orientation);
      return this.obj.heading();
    },
    angle() {
      return { alpha: this.obj?.orientation?.alpha, angle: this.obj?.angle() };
    }
  },
  methods: {}
};
</script>

<style>
.geo-confirmed--text {
  color: #9dc4eb !important;
}
</style>
